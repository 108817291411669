import './index.css';
import ReactDOM from 'react-dom';
import React from 'react';
import App from 'src/components/App';

ReactDOM.render(<App />, document.getElementById('root'));

function registerSW() {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            // TODO: Use PUBLIC_URL instead after PR 130 is merged
            .register(`${PUBLIC_URL}/sw.js`)
            .then(registration => {
                // eslint-disable-next-line no-console
                console.log('SW registered: ', registration);
            })
            .catch(registrationError => {
                // eslint-disable-next-line no-console
                console.log('SW registration failed: ', registrationError);
            });
    });
}

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    registerSW();
}

if (process.env.NODE_ENV === 'development') {
    module.hot.accept();
    if (process.env.USE_SW === 'true') {
        // eslint-disable-next-line no-console
        console.log(
            'Service workers enabled via .env.development. To disable, set `USE_SW` to false.'
        );
        registerSW();
    } else {
        navigator.serviceWorker
            .getRegistrations()
            .then(function(registrations) {
                for (let registration of registrations) {
                    registration.unregister();
                    // eslint-disable-next-line no-console
                    console.log('Service worker unregistered.', registration);
                }
                if (registrations.length) {
                    // Force reload to load the app without SWs
                    location.reload();
                }
            })
            .catch(function(err) {
                // eslint-disable-next-line no-console
                console.log('Service Worker un-registration failed: ', err);
            });
    }
}
