import React, { useState, useEffect } from 'react';
import classes from './message.css';
import X from 'feather/x';
import Info from 'feather/info';
import Alert from 'feather/alert-triangle';
import CheckCircle from 'feather/check-circle';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions/app';
import anime from 'animejs';
import { number } from 'prop-types';

import { Button } from 'fronds/Controllers';

function Message(props) {
    let { dismissTime = 3 } = props;
    const [anim, setAnim] = useState();
    const { messages } = useSelector(state => state.app);
    const dispatch = useDispatch();
    const [messageNumber, setMessageNumber] = useState(0);
    const animProps = {
        targets: `.${classes.progressBar}`,
        duration: dismissTime * 1000,
        easing: 'linear',
        width: '0%',
        autoplay: false,
        complete: () => {
            dismiss();
        },
        delay: 500
    };

    const [currentMessage, setCurrentMessage] = useState({
        message:
            messages.length > 0 ? messages[messages.length - 1].message : null,
        type: messages.length > 0 ? messages[messages.length - 1].type : null
    });

    const iconMap = {
        ERROR: <Alert />,
        INFO: <Info />,
        SUCCESS: <CheckCircle />
    };

    useEffect(() => {
        setAnim(anime(animProps));
    }, []);

    useEffect(() => {
        if (messages.length > 0) {
            if (anim) {
                anim.restart();
            }
            const msg = messages[messages.length - 1];
            setCurrentMessage({
                ...msg
            });
        } else {
            setCurrentMessage(msg => {
                return {
                    ...msg,
                    message: null
                };
            });
        }
    }, [messages, anim]);

    // Don't restart the animation if this is the last message
    if (messageNumber !== messages.length && messages.length > 0) {
        setMessageNumber(messages.length);
        pauseAnim();
    }

    function getCount() {
        return messages.length > 1 ? `(${messages.length})` : null;
    }

    function getIcon() {
        return iconMap[currentMessage.type];
    }

    function dismiss() {
        dispatch(actions.dismissMessage());
        pauseAnim();
    }

    function dismissAll() {
        dispatch(actions.dismissAllMessages());
        pauseAnim();
    }

    function pauseAnim() {
        if (anim && anim.animatables.length > 0) {
            anim.pause();
        }
    }

    function getCloseAllButton() {
        return messages.length > 1 ? (
            <Button className={'smallInline'} onClick={() => dismissAll()}>
                Clear all
            </Button>
        ) : null;
    }

    let rootClass = classes.root;

    // Type property is kept so the message box doesn't
    // lose it's color when it transitions back below the window.
    if (currentMessage.type) {
        rootClass += ` ${classes[currentMessage.type]}`;
    }

    if (currentMessage.message) {
        rootClass += ` ${classes.visible}`;
    }

    return (
        <div className={rootClass}>
            <div className={classes.messageContainer}>
                <div
                    className={classes.progressBar}
                    style={{
                        width: '100%'
                    }}
                />
                <div className={classes.messageHeader}>
                    <h4>
                        {currentMessage.type} {getCount()}
                        {getCloseAllButton()}
                    </h4>
                    <span className={classes.closeIcon}>
                        <X onClick={() => dismiss()} />
                    </span>
                </div>
                <div className={classes.message}>
                    {getIcon()}
                    {currentMessage.message}
                </div>
            </div>
        </div>
    );
}

Message.propTypes = {
    dismissTime: number
};

export default Message;
