import React, { Suspense, useEffect, useState } from 'react';
import { View, Router } from 'react-navi';
import classes from './App.css';

import ConfirmationDialogue from 'src/components/ConfirmationDialogue';
import { useDispatch, useSelector } from 'react-redux';
import Message from 'src/components/Message';
import { LazyCreate } from 'src/components/OverrideModal';
import store from 'src/store';
import { Provider } from 'react-redux';
import navigation from 'src/util/navigation';
import { initApp } from 'src/store/actions/appAsyncActions';

function App() {
    const dispatch = useDispatch();
    const { roles } = useSelector(state => state.user);
    const [override, setOverride] = useState(null);

    useEffect(() => {
        dispatch(initApp());
    }, []);

    useEffect(() => {
        if (roles) {
            setOverride(<LazyCreate />);
        }
    }, [roles]);

    return (
        <Router basename={PUBLIC_URL} navigation={navigation}>
            <Suspense fallback={null}>
                <View />
            </Suspense>
            <Message />
            <div className={classes.override}>{override ? override : null}</div>
            <ConfirmationDialogue />
        </Router>
    );
}

function Wrapper(props) {
    return (
        <Provider store={store}>
            <App {...props} />
        </Provider>
    );
}

export default Wrapper;
