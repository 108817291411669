import React from 'react';
import classes from './overrideModal.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import tableActions from 'src/store/actions/table';
import appActions from 'src/store/actions/app';
import amountType from 'src/constants/overrideAmountType';
const { dollar, percent } = amountType;
import safeAccessInvoice from 'src/util/safeAccessInvoice';
import { Modal } from 'fronds/Components';

import OverrideAmountForm from './overrideAmountForm';

function OverrideModal(props) {
    const {
        title,
        selectedRow,
        type = dollar,
        allowComments = false,
        setType = () => {},
        formState
    } = props;
    const isOverrideOpen = useSelector(state => state.app.isOverrideOpen);
    const dispatch = useDispatch();

    const grossProfitAmount = safeAccessInvoice(
        selectedRow,
        'grossProfitAmount'
    );

    const displayTitle = `${title} | ${selectedRow &&
        safeAccessInvoice(selectedRow, 'invoiceNumber')}`;

    const percentClass =
        type === percent ? classes.percentWrapper : classes.noPercent;

    const onClose = () => {
        dispatch(tableActions.setSelectedRow(null));
        dispatch(appActions.closeOverride());
    };

    return selectedRow && isOverrideOpen ? (
        <Modal
            className={classes.root}
            title={displayTitle}
            collapsible={true}
            onClose={onClose}
            submitButtonProps={{
                disabled: !formState.isValid
            }}
        >
            <OverrideAmountForm
                {...props}
                percentClass={percentClass}
                setType={setType}
                grossProfitAmount={grossProfitAmount}
                allowComments={allowComments}
            />
        </Modal>
    ) : null;
}

OverrideModal.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    onSubmit: PropTypes.func,
    selectedRow: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    type: PropTypes.oneOf([dollar, percent]),
    setType: PropTypes.func,
    allowComments: PropTypes.bool,
    isCommentRequired: PropTypes.bool,
    formState: PropTypes.object
};

export default OverrideModal;
