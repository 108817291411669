import React from 'react';
import classes from './confirmationDialogue.css';
import { Modal } from 'fronds/Components';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions/app';

function ConfirmationDialogue() {
    const { confirmationMessage } = useSelector(state => state.app);
    const dispatch = useDispatch();

    function onClick(event) {
        if (event.target === event.currentTarget) {
            if (confirmationMessage && confirmationMessage.onCancel) {
                confirmationMessage.onCancel();
            } else {
                dispatch(actions.setConfirmationDialogue(null));
            }
        }
    }

    if (confirmationMessage) {
        return (
            <div onClick={onClick} className={classes.root}>
                <Modal {...confirmationMessage} />
            </div>
        );
    }
    return null;
}

export default ConfirmationDialogue;
